import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getCroContexts,
  redirectToPropertyOrNoPermissionsPage,
} from 'frontend-container/components/Menu/components/CroContext/service';
import { getPropertyContexts } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { PropertySelectorReadOnly } from 'frontend-container/components/Menu/components/PropertySelectorReadOnly/PropertySelectorReadOnly';
import { isPropertySelectorReadOnlyVisible } from 'frontend-container/components/Menu/components/PropertySelectorReadOnly/visibility';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';
import { useRouterContext } from 'frontend-container/components/Router/context/context';
import { useSelectContext } from 'frontend-container/components/Router/utils/useSelectContext';
import { redirectToProperRegionIfNecessary } from 'frontend-container/utils/region/redirectToProperRegionIfNecessary';

import { ContextComponent } from '../Context';

import { useCroDateTime } from './useCroDateTime';

interface Props {
  isContextSwitchHidden?: boolean;
  triggerId?: string;
}

export const CroContext = ({
  isContextSwitchHidden,
  triggerId,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const selectedCro = useRouterContext((store) => store.selectedCro);
  const onSelect = useSelectContext();

  const isMenuV2Enabled = getIsMenuV2Enabled();

  useEffect(() => {
    if (isMenuV2Enabled) {
      return;
    }

    if (selectedCro) {
      const redirected = redirectToProperRegionIfNecessary(selectedCro);
      if (redirected) {
        return;
      }
    }

    if (getCroContexts().length === 0) {
      redirectToPropertyOrNoPermissionsPage(getPropertyContexts().length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !selectedCro ? (
    <ac-loader-covering />
  ) : (
    <>
      {isPropertySelectorReadOnlyVisible() && !isMenuV2Enabled && (
        <PropertySelectorReadOnly />
      )}
      <ContextComponent
        selectedContext={selectedCro}
        onSelect={onSelect}
        useDateTime={useCroDateTime}
        dateLabel={t('MENU.CONTEXT.CRO.DATE')}
        timeLabel={t('MENU.CONTEXT.CRO.TIME')}
        isContextSwitchHidden={isContextSwitchHidden}
        triggerId={triggerId}
      />
    </>
  );
};
